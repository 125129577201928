import styled from '@emotion/styled';
import { useState, useCallback } from 'react';
import BlogThumbnail from './BlogThumbnail';
import { useQuery } from 'react-query';
import { Skeleton } from '@mui/material';

const BlogOverviewContainer = styled.div`

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  min-height: calc(100vh - 9rem);
  gap: 15px;

  & > h1 {
    font-family: Rodrigues;
    color: #ea86a4;
    font-size: 70px;
    text-align: center;
  }

`;

const BlogContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  gap: 25px;
  margin: 25px;
`;

const BlogOverview = () => {

  const [blogItems, setBlogItems] = useState([]);

  const reloadData = useCallback(async () => {
    const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'blog');
    const data = await response.json();
    setBlogItems(data);
  }, []);

  const { status } = useQuery('blog_articles', reloadData);

  if(status === 'loading') {
      return(
        <BlogOverviewContainer>
          <h1>Wissensblog</h1>
          <div style={{display: 'flex', gap: '15px', flexWrap: 'wrap', justifyContent: 'center', marginBottom: '25px'}}>
            <div>
              <Skeleton variant="text" />
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="rectangular" width={310} height={118} />
            </div>
            <div>
              <Skeleton variant="text" />
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="rectangular" width={310} height={118} />
            </div>
            <div>
              <Skeleton variant="text" />
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="rectangular" width={310} height={118} />
            </div>
          </div>
        </BlogOverviewContainer>
      );
  }

  return (
    <BlogOverviewContainer>
      <h1>Wissensblog</h1>
    </BlogOverviewContainer>
  );
};

export default BlogOverview;
