import { Menu } from '@mui/material';
import { Fragment } from 'react';

interface DropdownMenuProps {
  anchor?: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  menuItems: Array<JSX.Element>;
}

function DropdownMenu(props: DropdownMenuProps) {
  return (
    <Fragment>
      <Menu
        anchorEl={props.anchor}
        open={props.open}
        onClose={props.onClose}
        onClick={props.onClick}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {props.menuItems.map((e, i) => {
          return e;
        })}
      </Menu>
    </Fragment>
  );
}

export default DropdownMenu;
