import { useState } from 'react';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import BlogArticle from '../components/Blog/BlogArticle';
import Modal from '../components/Modal/Modal';

const BlogItem = () => {
  const [modalState, setModalState] = useState(false);
  const toggleModalState = () => setModalState(!modalState);
  const [messageState] = useState(false);

  return (
    <div>
      <Modal
        modalState={modalState}
        toggleModalState={toggleModalState}
        emailAddress={undefined}
        messageState={messageState}
      />{' '}
      <Navbar toggleModalState={toggleModalState} />
      <BlogArticle toggleAuthState={toggleModalState} />
      <Footer />
    </div>
  );
};

export default BlogItem;
