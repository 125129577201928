import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { Drawer } from '@mui/material';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import PsychologyIcon from '@mui/icons-material/Psychology';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Fragment } from 'react';

interface DrawerProps {
    drawerOpen: boolean;
    toggleDrawerState: () => void;
    loggedIn: boolean;
    logout: () => void;
    toggleModalState: () => void;
}

interface DrawerItem {
    title: string;
    path: string;
    icon: JSX.Element;
}

const DrawerItems: Array<DrawerItem> = [
    {
        title: "HOME",
        path: "/",
        icon: <HomeIcon />
    },
    {
        title: "ÜBER UNS",
        path: "/aboutus",
        icon: <ApartmentIcon />
    },
    {
        title: "WISSENS-BLOG",
        path: "/blog",
        icon: <LibraryBooksIcon />
    }
];

const BlackColorStyle = {
    color: '#807e7e',
    fontStyle: 'normal',
    fontSize: '1.6rem'
}

const AuthenticationItem = (isAuthenticated: boolean, logout: any, toggleModalState: any, toggleDrawerState: any, routeTo: any) => {
    if(isAuthenticated) {
        return (
            <Fragment>
                <Divider />
            <ListItem button key="accountItem" style={BlackColorStyle} onClick={() => routeTo('/account')}>
                <ListItemIcon style={BlackColorStyle}><AccountCircleIcon /></ListItemIcon>
                <ListItemText primary="ACCOUNT" />
            </ListItem>
            <ListItem button key="authItem" style={{backgroundColor: '#ea86a4', color: 'white', fontStyle: 'normal'}} onClick={() => logout()}>
                <ListItemIcon style={{backgroundColor: '#ea86a4', color: 'white'}}><LogoutIcon /></ListItemIcon>
                <ListItemText primary="LOGOUT" />
            </ListItem></Fragment>
        );
    } else {
        const openLogin = () => {
            toggleDrawerState();
            toggleModalState();
        }
        return (
            <ListItem button key="authItem" style={{backgroundColor: '#99c4d9', color: 'white', fontStyle: 'normal'}} onClick={() => openLogin()}>
                <ListItemIcon style={{backgroundColor: '#99c4d9', color: 'white'}}><LoginIcon /></ListItemIcon>
                <ListItemText primary="LOGIN" />
            </ListItem>
        );
    }
}

const NavbarDrawer = (props: DrawerProps) => {

    let navigate = useNavigate();

    const routeTo = (path: string) => { navigate(path); }

    return (
        <Drawer
        anchor="left"
        open={props.drawerOpen}
        onClose={props.toggleDrawerState}>
            <Box sx={{ width: 250 }}>
                <List>
                {
                    DrawerItems.map((item) => (
                        <ListItem button key={item.title} style={BlackColorStyle} onClick={() => routeTo(item.path)}>
                            <ListItemIcon style={BlackColorStyle}>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItem>
                    ))
                }      
                <Divider />
                <h2 style={{marginTop: '15px', fontSize: '1.2rem', fontStyle: 'normal', textAlign: 'left', marginLeft: '15px    ', color: '#807e7e'}}>KURSE</h2>
                {
                    props.loggedIn && (
                            <ListItem button key="corona-kurs-item" style={BlackColorStyle} onClick={() => routeTo('/media')}>
                            <ListItemIcon style={BlackColorStyle}><VideoLibraryIcon /></ListItemIcon>
                            <ListItemText primary="MEDIATHEK" />
                        </ListItem>
                    )
                }
                        
                        <ListItem button key="corona-kurs-item" style={BlackColorStyle} onClick={() => routeTo('/courses')}>
                            <ListItemIcon style={BlackColorStyle}><CoronavirusIcon /></ListItemIcon>
                            <ListItemText primary="Stark trotz Krise" />
                        </ListItem>
                        <ListItem button key="corona-kurs-item" style={BlackColorStyle} onClick={() => routeTo('/courses-ukraine')}>
                            <ListItemIcon style={BlackColorStyle}><PsychologyIcon /></ListItemIcon>
                            <ListItemText primary="Sorgen um die Ukraine" />
                        </ListItem> 
                    {AuthenticationItem(props.loggedIn, props.logout, props.toggleModalState, props.toggleDrawerState, routeTo)}
                </List>
            </Box>
        </Drawer>
    );

}

export default NavbarDrawer;