var CryptoJS = require('crypto-js');

const encrypt = (value) => {
  const key = CryptoJS.enc.Utf8.parse('local-password-encryption');
  const iv = getIv(key);
  let srcs = CryptoJS.enc.Utf8.parse(value);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.ciphertext.toString().toUpperCase();
};

function getIv(ivKey) {
  return CryptoJS.enc.Utf8.parse(ivKey);
}

export default encrypt;
