import * as React from 'react';
import { styled as muiStyles} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Fragment } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';

const BootstrapDialog = muiStyles(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
  }

const DialogTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CloseButtonContainer = styled.div`
    
  outline: 1px solid #f0f0;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  background-color: #ea86a4;
  color: white;

  &:hover {
    background-color: #e3819f;
    transition: 1s background-color;
    cursor: pointer;
  }

`;

  const BootstrapDialogTitle = (props: DialogTitleProps) => {

    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
      </DialogTitle>
    );
  };

  interface CustomDialogProps {
      open: boolean;
      setOpen: (value: boolean) => void;
      handleClickOpen: () => void;
      handleClose: () => void;
      children?: any;
      title: string;
      canBeSubmitted: boolean;
      isFullScreen: boolean;
      handleSubmit: (e: any) => void;
      actionButtonTitle: string;
      abortButtonTitle: string;
  }

  export default function CustomDialog(props : CustomDialogProps) {
        
    const {title, open, handleClose, children, handleSubmit, canBeSubmitted, isFullScreen, actionButtonTitle, abortButtonTitle} = props;

    return (
      <Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open} style={{width: '100%'}}
          fullScreen={isFullScreen}
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            <DialogTitleContainer>
              {title}
              <CloseButtonContainer>
                <CloseIcon onClick={handleClose} />
              </CloseButtonContainer>
            </DialogTitleContainer>
          </BootstrapDialogTitle>
          <DialogContent dividers>
            {children}
          </DialogContent>
          <DialogActions>
            <button disabled={!canBeSubmitted} autoFocus onClick={handleSubmit}>
              {actionButtonTitle}
            </button>
            <button style={{backgroundColor: '#ea86a4'}} autoFocus onClick={handleClose}>
              {abortButtonTitle}
            </button>
          </DialogActions>
        </BootstrapDialog>
      </Fragment>
    );
  }