import './App.scss';

import Landingpage from './pages/Landingpage';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { QueryClientProvider, QueryClient } from 'react-query';

import ProtectedRoute from './guards/ProtectedRoute';
import AdministrationRoute from './guards/AdministrationRoute';
import NewsletterPage from './pages/NewsletterPage';
import PasswordResetPage from './pages/PasswordResetPage';
import PasswordReset from './pages/PasswordReset';
import NotFound from './pages/NotFound';
import MailActions from './pages/MailActions';
import VideoPage from './pages/VideoPage';
import AboutUs from './pages/AboutUs';
import Blog from './pages/Blog';
import BlogItem from './pages/BlogItem';
import CourseIntro from './pages/courses/corona/CourseIntro';
import CourseIntroUkraine from './pages/courses/depression/CourseIntro';
import LoginPage from './pages/Login';
import RegistrationPage from './pages/Registration';
import DataPrivacy from './pages/DataPrivacy';
import Imprint from './pages/Imprint';
import { StyledProvider } from 'components-extra';
import Contact from './pages/Contact';
import Account from './pages/Account';
import MemberArea from './pages/MemberArea';

const queryClient = new QueryClient();

function App() {

  const memberArea = <ProtectedRoute target={<MemberArea />} />;
  const videoPage = <ProtectedRoute target={<VideoPage />} />;
  const account = <ProtectedRoute target={<Account />} />;

  return (
    <div className="App main-container">
      <StyledProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Routes>
              <Route path="/" element={<Landingpage />}></Route>
              <Route path="/media" element={memberArea}></Route>
              <Route path="/video" element={videoPage}></Route>
              <Route path="/newsletter" element={<NewsletterPage />}></Route>
              <Route path="/password-reset" element={<PasswordResetPage />}></Route>
              <Route path="/mail-action" element={<MailActions />}></Route>
              <Route path="/404" element={<NotFound />}></Route>
              <Route path="/administration" element={<AdministrationRoute />}></Route>
              <Route path="/aboutus" element={<AboutUs></AboutUs>}></Route>
              <Route path="/blog" element={<Blog />}></Route>
              <Route path="/blog/article" element={<BlogItem />}></Route>
              <Route path="/courses" element={<CourseIntro />}></Route>
              <Route path="/courses-ukraine" element={<CourseIntroUkraine />}></Route>
              <Route path="/login" element={<LoginPage />}></Route>
              <Route path="/registration" element={<RegistrationPage />}></Route>
              <Route path="/password" element={<PasswordReset />}></Route>
              <Route path="/privacy" element={<DataPrivacy />}></Route>
              <Route path="/imprint" element={<Imprint />}></Route>
              <Route path="/contact" element={<Contact />}></Route>
              <Route path="/account" element={account}></Route>
              <Route path="*" element={<NotFound />}></Route>
            </Routes>
          </Router>
        </QueryClientProvider>
      </StyledProvider>
    </div>
  );
}

export default App;
