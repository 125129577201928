import './VideoGallery.scss';
import VideoThumbnail from './VideoThumbnail/VideoThumbnail';
import { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { decode } from '../../utils/JwtHelper';
import styled from '@emotion/styled';

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
  min-height: calc(100vh - 7rem);
  font-size: 1.2rem;
  font-weight: thin;
`;


const VideoGallery = () => {
  const [categories, setCategories] = useState([]);

  const parsedUser = JSON.parse(localStorage.getItem('user'));
  const user = decode(parsedUser.accessToken);
  const apiUrl = process.env.REACT_APP_API_BASE_URL + 'videos/' + user.code.user_id;

  const getVideos = useCallback(async () => {
    const response = await fetch(apiUrl, {
      headers: new Headers({
        Authorization: 'Bearer ' + parsedUser.accessToken,
      }),
    });
    const data = await response.json();
    setCategories(data);
  }, [apiUrl]);

  useEffect(() => {
    getData();
    async function getData() {
      try {
        getVideos();
      } catch (error) {
        console.log(error);
      }
    }
  }, [getVideos]);

  return (
    <GalleryContainer>
      <Helmet>
        <title>mindme - Kursüberblick</title>
        <meta
          name="description"
          content="Online-Kurse gegen psychologische Beschwerden. Ohne Wartezeit & sofort zugänglich – wann und wo immer du möchtest. Hilfe bei Depressionen, Partnerschaftsproblemen, Angststörungen uvm. Mit der Diplom-Psychologin Dagmara Gawin."
        />
        <meta charSet="utf-8" />
        <meta name="author" content="mindme" />
        <meta
          name="keywords"
          content="Corona, Pandemie, Krieg, Ukraine, Russland, Psychologe, Psychotherapeut, Psychotherapie, Coaching, Krise, Depression, Panikstörung, Angststörung, Partnerschaftsprobleme, Streit"
        />
        <link rel="canonical" href="http://www.mindme.de/media" />
      </Helmet>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          gap: '10px',
        }}
      >
        <h1
          style={{
            fontFamily: 'Rodrigues',
            color: '#EA86A4',
            fontSize: '8em',
            textAlign: 'center',
          }}
        >
          Deine Kurse
        </h1>
      </div>
      {categories.map((category, index) => (
        <div key={category.name} className="category">
          <div className="category-name" key={index}>
            {category.name}
          </div>
          <div className="thumbnails" style={{ backgroundColor: 'rgba(0, 0, 0, 0.01)' }}>
            {category.videos.map((video, idx) => (
              <VideoThumbnail
                video={video}
                number={idx}
                inHistory={video.progress > 0}
                seconds={video.progress || 0}
                key={idx}
              ></VideoThumbnail>
            ))}
          </div>
        </div>
      ))}
    </GalleryContainer>
  );
};

export default VideoGallery;
