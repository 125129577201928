import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import NewsletterOverview from '../components/Newsletter/NewsletterOverview';

import { Fragment, useState } from 'react';

import './AdminPage.scss';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AdminPage = () => {

  const [modalState, setModalState] = useState(false);
  const [value, setValue] = useState(0);
  const toggleModalState = () => setModalState(!modalState);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const SelectedTabStyle = {
    backgroundColor: '#ea86a4',
    color: 'white',
  };

  return (
    <Fragment>
      <Navbar toggleModalState={toggleModalState} />
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Newsletter" {...a11yProps(0)} style={value === 0 ? SelectedTabStyle : {}} />
        <Tab label="Videos" {...a11yProps(1)} style={value === 1 ? SelectedTabStyle : {}} />
        <Tab label="User" {...a11yProps(2)} style={value === 2 ? SelectedTabStyle : {}} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <NewsletterOverview></NewsletterOverview>
      </TabPanel>
      <Footer></Footer>
    </Fragment>
  );
};

export default AdminPage;
