import { FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import CustomDialog from "../Dialog/Dialog";

interface PrivacySettings {
    allSelected: boolean;
    essential: boolean;
    functional: boolean;
    marketing: boolean;
}

const Cookies = () => {

    const [open, setOpen] = useState(!hasGivenCookieAgreement());
    const [isDetailView, setIsDetailView] = useState(false);

    const [customPrivacySettings, setCustomPrivacySettings] = useState({
        allSelected: false,
        essential: false,
        functional: true,
        marketing: true
    });

    function hasGivenCookieAgreement() {
        return null !== localStorage.getItem('cookie_agreement');
      }
    
      function updateLocalStorage(privacy?: PrivacySettings) {
        const getData = async () => {
          const res = await fetch('https://geolocation-db.com/json/');
          return await res.json();
        };
        getData().then((response) => {
          const givenAgreement = {
            ip: response.IPv4,
            date: Date.now,
            privacySettings: privacy ? customPrivacySettings : {
                allSelected: true,
                essential: true,
                functional: true,
                marketing: true
            }
          };
          localStorage.setItem('cookie_agreement', JSON.stringify(givenAgreement));
          handleClose();
        });
      }

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    
    const handleSubmit = () => {
        updateLocalStorage();
    }

    const handleDetailSubmit = () => {
        updateLocalStorage(customPrivacySettings);
    }
    
    const CustomDialogProps = {
        open: open,
        setOpen: setOpen,
        handleClickOpen: handleClickOpen,
        handleClose: handleClose,
        handleSubmit: handleSubmit,
        canBeSubmitted: true,
        title: 'Wir respektieren ihre Privatspäre',
        isFullScreen: false,
        actionButtonTitle: 'Alles akzeptieren',
        abortButtonTitle: 'Alles ablehnen'
    }


    const switchBackToNonDetailView = () => {
        setIsDetailView(false);
    }

    const CustomDialogPrivacyProps = {
        open: open,
        setOpen: setOpen,
        handleClickOpen: handleClickOpen,
        handleClose: switchBackToNonDetailView,
        handleSubmit: handleDetailSubmit,
        canBeSubmitted: true,
        title: 'Privacy Einstellungen',
        isFullScreen: false,
        actionButtonTitle: 'Speichern',
        abortButtonTitle: 'Zurück'
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.name === 'allSelected' && event.target.checked) {
            setCustomPrivacySettings({
                allSelected: true,
                essential: true,
                functional: true,
                marketing: true
            });
        } else {
            setCustomPrivacySettings({
                ...customPrivacySettings,
                [event.target.name]: event.target.checked,
            });
        }
      };

    return (
        <Fragment>
        {
            !isDetailView && (
                <CustomDialog {...CustomDialogProps}>
                    Für ein optimales Website-Erlebnis nutzen wir Cookies und weitere Online-Technologien, 
                    um personalisierte Inhalte zu zeigen, Funktionen anzubieten und Statistiken zu erheben. 
                    Ihr Klick auf „Akzeptieren“ erlaubt uns diese Datenverarbeitung sowie die Weitergabe an Drittanbieter (auch in Drittländern) 
                    gemäß unserer Datenschutzerklärung. Cookies lassen sich jederzeit ablehnen oder in den Einstellungen anpassen.
                    <button style={{marginTop: '15px'}} onClick={() => setIsDetailView(true)}>Details anzeigen</button>
                </CustomDialog> 
            )
        }
        {
            isDetailView && (   
                <CustomDialog {...CustomDialogPrivacyProps}>
                    Dieses Tool hilft ihnen, verschiedene Tags, Tracker und Analyse-Tools auf dieser Website auszuwählen und zu deaktivieren.
                    <FormGroup>
                        <FormControlLabel control={<Switch checked={customPrivacySettings.allSelected} onChange={handleChange} name="allSelected" color="secondary"/>} label="Alles auswählen" style={{marginTop: '15px'}}/>
                        <FormControlLabel control={<Switch checked={customPrivacySettings.essential} onChange={handleChange} name="essential" color="secondary"/>} label="Essentiell"  style={{marginTop: '15px'}} />
                        <Typography variant="subtitle1" style={{textAlign: 'left'}} color="text.secondary">Diese Technologien sind erforderlich, um die Kernfunktionalität der Website zu aktivieren.</Typography>
                        <FormControlLabel control={<Switch defaultChecked color="secondary" onChange={handleChange} name="functional" checked={customPrivacySettings.functional}/>} label="Funktional"  style={{marginTop: '15px'}}/>
                        <Typography variant="subtitle1" style={{textAlign: 'left'}} color="text.secondary">Diese Technologien ermöglichen es uns, die Nutzung der Website zu analysieren, um die Leistung zu messen und zu verbessern.</Typography>
                        <FormControlLabel control={<Switch defaultChecked color="secondary" onChange={handleChange} name="marketing" checked={customPrivacySettings.marketing}/>} label="Marketing"  style={{marginTop: '15px'}}/>
                        <Typography variant="subtitle1" style={{textAlign: 'left'}} color="text.secondary">Diese Technologien werden von Werbetreibenden verwendet, um Anzeigen zu schalten, die für Ihre Interessen relevant sind.</Typography>
                    </FormGroup>
                </CustomDialog> 
            )
        }
        </Fragment>
    );

}

export default Cookies;