import styled from "@emotion/styled";
import { Fragment, useRef, useState } from "react"
import { Helmet } from "react-helmet";
import Footer from '../components/Footer/Footer';
import Modal from '../components/Modal/Modal';
import Navbar from '../components/Navbar/Navbar';
import Avatar from '@mui/material/Avatar';
import { Badge, Card, CardContent, Divider, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { decode } from "../utils/JwtHelper";
import TextField from '@mui/material/TextField';
import { getPatchRequestOptions, getPostRequestOptions, getPostRequestOptionsWithMultipart } from "../utils/HttpMethods";
import { Spinner, Toast, ToastContainer } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import CustomDialog from "../components/Dialog/Dialog";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 9rem);
    margin: 15px;
`;

const AvatarContainer = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    gap: 15px;
    vertical-align: center;

    & > div:hover {
        cursor: pointer;
        transform: scale(1.05);
        transform: 
        transition: transform 0.5s;
    }

`;

const NewsletterContainer = styled.div`

    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 15px;

`;

const AccountDeletionContainer = styled.div`

    display: flex;
    flex-direction: row;
    margin-top: 15px;
    gap: 5px;

`;

const UserInfoContainer = styled.div`

    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 15px;
    gap: 15px;

`;

const SaveButton = styled.button`

  &:after {
    content: "Änderungen speichern";
  }

  @media only screen and (max-width: 955px) {
    &:after {
        content: 'Speichern';
    }
  }

`;

const DeleteButton = styled.button`

  &:after {
    content: "Account löschen";
  }

  @media only screen and (max-width: 955px) {
    &:after {
        content: 'Löschen';
    }
  }

`;

interface UserInfo {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    hasNewsletterPermission: boolean;
    newsletterState: string;
    avatar: string;
}

interface UserProfileUpdateResource {
    firstName: string;
    lastName: string;
    email: string;
    hasNewsletterPermission: boolean;
    avatarId?: number | null;
}

interface AvatarInfo {
    id?: number;
    data: string;
}

const Account = () => {

    const [userRaw, setUserRaw] = useState(getUser());

    const fetchAvatar = async () => {
        const user = getUser();
        const apiUrl = process.env.REACT_APP_API_BASE_URL + 'users/' + user.code.user_id + '/avatars';
        const response = await fetch(apiUrl);
        return await response.json();
      }
    
    const { data, refetch } = useQuery('avatar', fetchAvatar);

    const user: UserInfo = {
        id: userRaw.code.user_id,
        name: userRaw.code.user_firstname + ' ' + userRaw.code.user_lastname,
        firstName: userRaw.code.user_firstname,
        lastName: userRaw.code.user_lastname,
        email:  userRaw.code.user_email,
        avatar: data?.data,
        hasNewsletterPermission: userRaw.code.user_has_given_newsletter_permission,
        newsletterState: determineNewsletterState(userRaw.code.user_has_given_newsletter_permission)
    }

    const [modalState, setModalState] = useState(false);
    const [email, setEmail] = useState(user.email);
    const [avatar, setAvatar] = useState<AvatarInfo | null>(user.avatar ? {data: user.avatar} : null);

    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);

    const [newsletterPermission, setNewsletterPermission] = useState(determineNewsletterState(user.hasNewsletterPermission));
    const toggleModalState = () => setModalState(!modalState);
    const [showToast, setShowToast] = useState(false);
    const [toastTitle, setToastTitle] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const fileUpload = useRef(document.createElement("input"));
    const [showSpinner, setShowSpinner] = useState(false);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
      };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
      setShowSpinner(true);
      deleteUserMutation.mutate();
    }

    const deleteUser = async () => {
        await fetch(apiUrl + '/delete-user-submit', getPostRequestOptions(null, true)).then(() => {
          setShowSpinner(false);
        });
    }
    
    const deleteUserMutation = useMutation(deleteUser, {
        onSuccess: (data) => {
            deleteUser();
            handleClose();
            setToastTitle("Accountlöschung beantragt");
            setToastMessage("Bitte prüfe dein Postfach.");
            setShowToast(true);
        },
        onError: () => {
          alert('there was an error: ' + deleteUserMutation.status);
        },
        onSettled: () => {
          queryClient.invalidateQueries('delete');
        },
      });

    const CustomDeleteDialogProps = {
        open: open,
        setOpen: setOpen,
        handleClickOpen: handleClickOpen,
        handleClose: handleClose,
        handleSubmit: handleSubmit,
        canBeSubmitted: true,
        title: 'Account löschen',
        isFullScreen: false,
        actionButtonTitle: 'Löschen',
        abortButtonTitle: 'Abbrechen'
      }

    const queryClient = useQueryClient();

    const apiUrl = process.env.REACT_APP_API_BASE_URL + 'users/' + user.id;

    const updateUser = async () => {
        if(!hasChanges()) {
            setToastTitle('Oh Oh!');
            setToastMessage('Es konnte keine Änderung an deinem Account gefunden werden. Bitte prüfe deine Eingaben.');
            setShowToast(true);
            return;
        }
        const updatePayload: UserProfileUpdateResource = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            hasNewsletterPermission: getNewsletterState(newsletterPermission),
            avatarId: avatar ? avatar.id : null,
        };
        const response = await fetch(apiUrl + '/update', getPatchRequestOptions(JSON.stringify(updatePayload)));
        return response.json();
    }

    const updateUserMutation = useMutation(updateUser, {
        onSuccess: (data) => {
            localStorage.setItem('user', JSON.stringify(data));
            setUserRaw(getUser());
            refetch();
        },
        onError: () => {
          alert('there was an error: ' + updateUserMutation.status);
        },
        onSettled: () => {
          queryClient.invalidateQueries('delete');
        },
      });


    const uploadTemporaryAvatar = async (e: any) => {
        const apiUrl = process.env.REACT_APP_API_BASE_URL + 'users/' + user.id + '/avatars';
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', file.name);
        const response = await fetch(apiUrl, getPostRequestOptionsWithMultipart(formData));
        if (!response.ok) {
            throw Error(response.statusText);
        }
        setAvatar(await response.json());
    }

    const hasChanges = () => {
        if(email === user.email &&
            firstName === user.firstName &&
            lastName === user.lastName &&
            avatar?.data === user.avatar &&
            newsletterPermission === user.newsletterState) {
                return false;
        } else {
            return true;
        }
    };

    const avatarOnChange = (event: any) => {
        if(event.target.files) {
            const maxAllowedSize = 1024 * 1024;
            if(event.target.files[0].size > maxAllowedSize) {
                setToastTitle('Oh Oh Oh!');
                setToastMessage('Die Datei ist leider zu groß. Avatar dürfen die maximale Dateigröße von 1 MB nicht überschreiten.');
                setShowToast(true);
            } else {
                uploadTemporaryAvatar(event);
            }
        }
    }

    const handleClickOnAvatar = () => {
        if(fileUpload) {
            fileUpload.current.click();
        }
    }

    const firstNameOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    };

    const lastNameOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    };

    const emailOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const newsletterStateOnChange = (
        event: React.MouseEvent<HTMLElement>,
        permission: string,
      ) => {
        setNewsletterPermission(permission);
      };    

    return (
    <Fragment>
        <Helmet>
        <title>mindme - Account</title>
        <meta
          name="description"
          content="Psychologische Beratung für deine mentale Gesundheit (47) durch die Diplom-Psychologin Dagmara Gawin"
        />
        <meta charSet="utf-8" />
        <meta name="author" content="mindme" />
        <meta name="keywords" content="Psychologie, Gesundheit, Mindme, mindme, Beratung, Hilfe" />
        <link rel="canonical" href="http://www.mindme.de/account" />
      </Helmet>
      <Modal modalState={modalState} toggleModalState={toggleModalState} messageState={false}></Modal>
      <Navbar toggleModalState={toggleModalState}></Navbar>
      <Container>
      <Card sx={{ width: 700 }}>
        <CardContent>
            <AvatarContainer>
                <input type="file" accept="image/png, image/jpeg" onChange={(e) => avatarOnChange(e)} ref={fileUpload} hidden/> 
                <Tooltip title="Avatar ändern">
                    <div>
                    <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} badgeContent={
                        <div style={{backgroundColor: '#99c4d9', borderRadius: '50%', padding: '5px'}}>
                            <AddAPhotoIcon style={{color: '#fff', fontSize: '1rem'}}/>
                        </div>
                    }>
                    {
                        avatar && (
                            <Avatar alt={user.name} src={'data:image/jpg;base64,' + avatar.data} sx={{ width: 72, height: 72, border: '1px solid rgb(239, 239, 239)'}} onClick={() => handleClickOnAvatar()}/>
                        )
                    }
                    {
                        !avatar && (
                            <Avatar alt={user.name} sx={{ width: 72, height: 72 }} onClick={() => handleClickOnAvatar()}/>
                        )
                    }
                  </Badge>  </div>
                </Tooltip>
            </AvatarContainer>
            <UserInfoContainer>
            <h6><b>Allgemein:</b></h6>

            <TextField
          id="outlined-required"
          label="Vorname"
          onChange={firstNameOnChange}
          defaultValue={firstName}
        />
         <TextField
          id="outlined-required"
          label="Nachname"
          onChange={lastNameOnChange}
          defaultValue={lastName}
        />
        <TextField
          id="outlined-required"
          label="E-Mail"
          onChange={emailOnChange}
          defaultValue={email}
        />
           </UserInfoContainer>
            <Divider/>
            <NewsletterContainer>
                <h6><b>Newsletter abonnieren:</b></h6>
                <ToggleButtonGroup size="small"
                    value={newsletterPermission}
                    exclusive
                    onChange={newsletterStateOnChange}>
                    <ToggleButton value="on" key="on">
                        JA
                    </ToggleButton>
                    <ToggleButton value="off" key="off">
                        NEIN
                    </ToggleButton>
                </ToggleButtonGroup>
            </NewsletterContainer>
            <Divider/>
            <AccountDeletionContainer>
                <SaveButton disabled={!hasChanges()} onClick={() => updateUserMutation.mutate()} />
                <DeleteButton onClick={() => handleClickOpen()} style={{backgroundColor: '#ea86a4'}}></DeleteButton>
            </AccountDeletionContainer>
        </CardContent>
      </Card>
      </Container>
      <CustomDialog {...CustomDeleteDialogProps} >
        {
          showSpinner && 
          ( <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><Spinner as="span" animation="border" role="status" aria-hidden="true" /></div> )
        }
        {
          !showSpinner &&
          (<Fragment>
              <h6><b>Es ist schade, dass du uns verlässt.</b> <SentimentVeryDissatisfiedIcon /></h6>
              <h6> Sobald dein Account gelöscht wurde, sind deine persönlichen Daten, Kommentare und deine Mediahistorie permantent gelöscht.</h6>
              <h6>Bitte bestätige die Löschung deines Kontos mit dem Knopf unten. Wir schicken dir dann einen Link an <u>{user.email}</u> um deine Kontolöschung entgültig abzuschließen.</h6>
            </Fragment>
          )
        }
         </CustomDialog>
      <ToastContainer position="bottom-end" className="p-3">
              <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                <Toast.Header>
                  <img
                    style={{ height: '20px', width: '20px' }}
                    src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2020%2020%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_17de1039b72%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3Avar(--bs-font-sans-serif)%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_17de1039b72%22%3E%3Crect%20width%3D%2220%22%20height%3D%2220%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2210%22%20y%3D%2210%22%3E%20%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                    className="rounded me-2"
                    alt="Dagmara Gawin"
                  />
                  <strong className="me-auto">{toastTitle}</strong>
                  <small>Vor 1 Sekunde</small>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
              </Toast>
            </ToastContainer>
      <Footer />
    </Fragment>
    )
}

function determineNewsletterState(state: boolean) {
    return state ? 'on' : 'off';
}

function getNewsletterState(state: string) {
    return state === 'on';
}

function getUser() {
    const jsonUser = localStorage.getItem('user');
    const parsedUser = jsonUser ? JSON.parse(jsonUser) : null;
    return parsedUser ? decode(parsedUser.accessToken) : null;
}

export default Account;