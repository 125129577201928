import styled from '@emotion/styled';
import { Paper, Typography } from '@mui/material';
import { useState } from 'react';
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';

const ImprintWrapper = styled.div`
  width: 100%;
`;

const Links = styled.a`
  color: #ea86a4;

  &:hover {
    text-decoration: underline;
    color: #ea86a4;
  }
`;

const StyledPaper = styled(Paper)`
  margin: 50px;
  padding: 25px;

  @media only screen and (max-width: 955px) {
    margin: 10px;
  }
`;

const Imprint = () => {
  const [modalState, setModalState] = useState(false);
  const toggleModalState = () => setModalState(!modalState);

  return (
    <ImprintWrapper>
      <Navbar toggleModalState={toggleModalState} />
      <StyledPaper elevation={3}>
        <Typography>
          <h2>Impressum</h2>
          <p>
            <b>Inhaltsverzeichnis</b>
          </p>
          <ol>
            <li>
              <Links href="#Angaben_gemäß_§_5_TMG" title="Angaben gemäß § 5 TMG">
                Angaben gemäß § 5 TMG
              </Links>
            </li>
            <li>
              <Links href="#Kontakt" title="Kontakt">
                Kontakt
              </Links>
            </li>
            <li>
              <Links
                href="#Das_Impressum_gilt_auch_für_folgende_Social_Media_Profile:"
                title="Das Impressum gilt auch für folgende Social Media Profile:"
              >
                Das Impressum gilt auch für folgende Social Media Profile:
              </Links>
            </li>
            <li>
              <Links href="#Haftung_für_Inhalte" title="Haftung für Inhalte">
                Haftung für Inhalte
              </Links>
            </li>
            <li>
              <Links href="#Haftung_für_Links" title="Haftung für Links">
                Haftung für Links
              </Links>
            </li>
            <li>
              <Links href="#Urheberrecht" title="Urheberrecht">
                Urheberrecht
              </Links>
            </li>
            <li>
              <Links href="#Zweckbestimmung" title="Zweckbestimmung">
                Zweckbestimmung
              </Links>
            </li>
            <li>
              <Links href="#Nebenwirkungen" title="Nebenwirkungen">
                Nebenwirkungen
              </Links>
            </li>
            <li>
              <Links href="#Angaben_für_Notfälle" title="Angaben für Notfälle">
                Angaben für Notfälle
              </Links>
            </li>
            <li>
              <Links href="#Datenschutzerklärung" title="Datenschutzerklärung">
                Datenschutzerklärung
              </Links>
            </li>
            <li>
              <Links href="#Interoperabilität" title="Interoperabilität">
                Interoperabilität
              </Links>
            </li>
            <li>
              <Links
                href="#Website_Konzeption,_Gestaltung_und_Programmierung"
                title="Website Konzeption, Gestaltung und Programmierung"
              >
                Website Konzeption, Gestaltung und Programmierung
              </Links>
            </li>
          </ol>
          <h3>
            <span id="Angaben_gemäß_§_5_TMG">
              <b>Angaben gemäß § 5 TMG</b>
            </span>
          </h3>
          <p>
            <strong>
              <b>Mindme</b>
            </strong>
            <br />
            Dagmara Gawin
            <br />
            Im Rundum 13
            <br />
            38124 Braunschweig
          </p>
          <h3>
            <span id="Kontakt">Kontakt</span>
          </h3>
          <p>
            E-Mail:&nbsp;<Links href="mailto:info@mindme.de">info@mindme.de</Links>
          </p>
          <h3>
            <span id="Das_Impressum_gilt_auch_für_folgende_Social_Media_Profile:">
              <b>Das Impressum gilt auch für folgende Social Media Profile:</b>
            </span>
          </h3>
          <p>
            Facebook:{' '}
            <Links
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/mindme/"
            >
              facebook.com/mindme
            </Links>
            <br />
            Twitter:{' '}
            <Links target="_blank" rel="noopener noreferrer" href="https://twitter.com/mindme">
              twitter.com/mindme
            </Links>
            <br />
            Instagram:{' '}
            <Links
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/mindme/"
            >
              instagram.com/mindme
            </Links>
          </p>
          <p>
            <h3>
              <b>Haftungsausschluss (Disclaimer)</b>
            </h3>
          </p>
          <p id="Haftung_für_Inhalte">
            <strong>
              <u>Haftung für Inhalte</u>
            </strong>
          </p>
          <p>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
            nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
            Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
            Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
            Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
            diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
            Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden
            wir diese Inhalte umgehend entfernen.
          </p>
          <p id="Haftung_für_Links">
            <strong>
              <u>Haftung für Links</u>
            </strong>
          </p>
          <p>
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen
            Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr
            übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
            Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
            Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
            Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der
            verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
            zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
            entfernen.
          </p>
          <p id="Urheberrecht">
            <strong>
              <u>Urheberrecht</u>
            </strong>
          </p>
          <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen
            dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art
            der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
            Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind
            nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
            dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
            beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
            trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
            Inhalte umgehend entfernen.
          </p>
          <h3>
            <span id="Zweckbestimmung">
              <b>Zweckbestimmung</b>
            </span>
          </h3>
          <p>Die Inhalte dienen den folgenden Zwecken:</p>
          <ol>
            <li>Vermittlung von gesundheitsbezogenen Informationen (Psychoedukation)</li>
            <li>
              Prophylaxe psychischer Störungen durch Selbstfürsorge und Ressourcenaktivierung.
            </li>
            <li>Zugang zu sicheren Techniken und Interventionen der kognitiven Therapie.</li>
          </ol>
          <p>
            <strong>
              <u>Indikationen</u>
            </strong>
          </p>
          <p>
            Die Inhalte bieten eine geeignete Selbsthilfe für Klienten mit einer der folgenden
            Indikationen:
          </p>
          <p>
            Die Inhalte, Informationen und Videokurse sowie Arbeitsblätter sind ausdrücklich keine
            psychotherapeutische Behandlung oder Diagnosestellung. Die Nutzer erhalten allgemeine
            Informationen zu Faktoren, die die psychische Gesundheit zu verbessern helfen. Den
            Nutzern soll Zugang zu fundierten Informationen aus dem Hilfsnetzwerk gegeben werden.
            Nutzer sollen aufgeklärt werden, welche Anlaufstellen es gibt. Nutzer sollen ermutigt
            werden, sich bei psychischen Problemen an ihren Hausarzt oder einen Therapeuten zu
            wenden. Die Online- Kurse ersetzen diese Gespräche oder Therapien nicht. Im Notfall
            sollen sich Nutzer immer an den Hausarzt oder im lebensbedrohlichen Notfall an die 112
            wenden.
          </p>
          <p>
            <strong>
              <u>Kontraindikationen</u>
            </strong>
          </p>
          <p>Die Online Kurse sind nicht für eine Anwendung vorgesehen bei:</p>
          <ul>
            <li>
              Allen schizophreniformen oder wahnhaften Störungen, Vorliegen einer bipolaren Störung
              oder einer psychotischen Störung (z. B. Schizophrenie): F2*; F31; F32.3; F33.3
            </li>
            <li>Vorliegen einer schweren depressiven Episode F32.2; F32.3</li>
            <li>Vorliegen einer akuten Substanzabhängigkeit oder Entzugssyndrom: F1*.2; F1*.3</li>
            <li>Personen mit akut suizidalen oder selbstverletzenden Tendenzen.</li>
            <li>
              Personen, die für die Anwendung von Selbsthilfe-Therapien ungeeignet sind, aufgrund
              geistiger, körperlicher oder anderweitig krankheitsbedingter Einschränkungen.
            </li>
            <li>Jugendlichen und Kindern unter 18 Jahren.</li>
          </ul>
          <p>
            <strong>
              <u>Anwendergruppen</u>
            </strong>
          </p>
          <ul>
            <li>
              Nicht akut suizidale oder anderweitig für Selbsthilfe-Therapie ungeeignete Erwachsene
              und Jugendliche.
            </li>
          </ul>
          <h3>
            <span id="Nebenwirkungen">
              <b>Nebenwirkungen</b>
            </span>
          </h3>
          <p>
            Bislang sind keine Nebenwirkungen bekannt. Sollten Sie im Zusammenhang mit der Nutzung
            von Selfapy Nebenwirkungen beobachten, melden Sie diese unverzüglich Ihrem Arzt oder
            Psychotherapeuten oder wenden Sie sich an{' '}
            <Links href="mailto:info@mindme.de">info@mindme.de</Links>.
          </p>
          <h3>
            <span id="Angaben_für_Notfälle">
              <b>Angaben für Notfälle</b>
            </span>
          </h3>
          <p>
            In Notfällen oder bei Bedarf wenden Sie sich an Ihren behandelnden Arzt oder
            Psychotherapeuten oder an die Telefonseelsorge. Die Telefonseelsorge können Sie rund um
            die Uhr erreichen unter den gebührenfreien Telefonnummern 0800 111 0 111 oder 0800 111 0
            222. Hier erhalten Sie kompetente, vertrauliche und anonyme Hilfe. Weitere Hilfe
            erhalten Sie auch unter den bekannten Notrufnummern Polizei: 110 und Feuerwehr: 112.
          </p>
          <h3>
            <span id="Datenschutzerklärung">
              <b>Datenschutzerklärung</b>
            </span>
          </h3>
          <p>
            Weitere Informationen zum Thema Datenschutz finden sie{' '}
            <Links href="/privacy">hier.</Links>
          </p>
        </Typography>
      </StyledPaper>
      <Footer />
    </ImprintWrapper>
  );
};

export default Imprint;
