import { useState, useEffect, useCallback } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import styled from "@emotion/styled";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useMutation, useQueryClient } from "react-query";
import CustomDialog from "../Dialog/Dialog";
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw } from "draft-js";
import { TextField } from "@mui/material";
import { decode } from "../../utils/JwtHelper";


const NewsletterWrapper = styled.div`
  min-height: calc(100vh - 11rem);
`;

const NewsletterOverview = () => {
  
  const queryClient = useQueryClient();
  const [newsletterState, setNewsletterState] = useState([]);
  const [selectedNewsletterIds, setSelectedNewsletterIds] = useState([]);

  const jsonUser = localStorage.getItem("user");
  const parsedUser = jsonUser ? JSON.parse(jsonUser) : null;

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "title", headerName: "Titel", width: 300, flex: 1 },
    { field: "content", headerName: "Inhalt", width: 400, flex: 1 },
    {
      field: "createdAt",
      headerName: "Erstellt am",
      width: 250,
    },
    {
      field: "createdBy",
      headerName: "Erstellt von",
      width: 225,
    },
    {
      field: "active",
      headerName: "Aktiv",
      width: 160,
    },
  ];

  const reloadData = useCallback(async () => {
    const response = await fetch(process.env.REACT_APP_API_BASE_URL + "newsletters", {
      headers: new Headers({
        Authorization: "Bearer " + parsedUser.accessToken,
      }),
    });
    const data = await response.json();
    setNewsletterState(data);
  }, [parsedUser.accessToken]);

  useEffect(() => {
    getData();
    async function getData() {
      try {
        reloadData();
      } catch (error) {
        console.log(error);
      }
    }
  }, [reloadData]);

  const deleteNewsletter = async () => {
    const payload = {
      ids: selectedNewsletterIds,
    };
    const response = await fetch(process.env.REACT_APP_API_BASE_URL + "newsletters", {
      headers: new Headers({
        Authorization: "Bearer " + parsedUser.accessToken,
      }),
      method: "DELETE",
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    setNewsletterState(data);
  };

  const deleteNewsletterMutation = useMutation(deleteNewsletter, {
    onSuccess: (data) => {},
    onError: () => {
      alert("there was an error: " + deleteNewsletterMutation.status);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  const selectNewsletters = (newsletterIds: any) => {
    setSelectedNewsletterIds(newsletterIds);
  };

  const isDeletionEnabled = () => {
    return selectedNewsletterIds.length > 0;
  };

  const [open, setOpen] = useState(false);
  const [openDeletion, setOpenDeletion] = useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenDeletionModal = () => {
    setOpenDeletion(true);
  };

  const handleCloseDeletionModal = () => {
    setOpenDeletion(false);
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  const [subject, setSubject] = useState('');

  const subjectOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(event.target.value);
};

  function onEditorStateChange(updatedEditorState: any) {
    setEditorState(updatedEditorState);
  }

  const createNewsletter = async (newsletter: NewsletterForm) => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL + 'newsletters';
    await fetch(
        apiUrl,
        getPutRequestOptions(JSON.stringify(newsletter)),
    );
  }

  function getPutRequestOptions(body: any) {

    const jsonUser = localStorage.getItem('user');
    const parsedUser = jsonUser ? JSON.parse(jsonUser) : null;
  
    return {
      method: 'PUT',
      body: body,
      headers: new Headers({
        Authorization: 'Bearer ' + parsedUser.accessToken,
        'Content-Type': 'application/json',
      }),
    };
  }

  interface NewsletterForm {
    title: string;
    content: string;
    createdBy: number;
    isActive: boolean;
  }

const handleSubmit = (e: any) => {
    e.preventDefault();
    const newsletter: NewsletterForm = {
       title: subject,
       content: htmlContent,
       isActive: true,
       createdBy: getUser().user_id
    }
    mutate(newsletter);
}

const handleDeletionSubmit = (e: any) => {
  e.preventDefault();
}

const getUser = () => {
  const jsonUser = localStorage.getItem('user');
  const parsedUser = jsonUser ? JSON.parse(jsonUser) : null;
  return parsedUser ? decode(parsedUser.accessToken).code : null;
}

const CustomDialogProps = {
  open: open,
  setOpen: setOpen,
  handleClickOpen: handleClickOpen,
  handleClose: handleClose,
  handleSubmit: handleSubmit,
  canBeSubmitted: subject.length > 0,
  title: 'Newsletter erstellen',
  isFullScreen: true,
  actionButtonTitle: 'Speichern',
  abortButtonTitle: 'Abbrechen'
}

const CustomDeleteDialogProps = {
  open: openDeletion,
  setOpen: setOpenDeletion,
  handleClickOpen: handleClickOpenDeletionModal,
  handleClose: handleCloseDeletionModal,
  handleSubmit: handleDeletionSubmit,
  canBeSubmitted: subject.length > 0,
  title: 'Newsletter löschen',
  isFullScreen: false,
  actionButtonTitle: 'Speichern',
  abortButtonTitle: 'Abbrechen'
}

  const { mutate, isLoading, isSuccess } = useMutation(createNewsletter, {
    onSuccess: () => {
        setOpen(false);
    },
    onSettled: () => {
      queryClient.invalidateQueries('create');
    },
});

  return (
    <NewsletterWrapper>
      <div style={{ display: "flex" }}>
        <button disabled={isDeletionEnabled()}  style={{ marginRight: "5px" }} onClick={handleClickOpen}>Hinzufügen</button>
        <button disabled={!isDeletionEnabled()} onClick={() => deleteNewsletterMutation}>
          Löschen
        </button>
      </div>
      <DataGrid
        rows={newsletterState}
        columns={columns}
        onSelectionModelChange={selectNewsletters}
        checkboxSelection
        style={{height: "calc(100vh - 11rem)"}}
      />
      <CustomDialog {...CustomDialogProps}>
      <TextField
          id="outlined-required"
          label="Betreff"
          value={subject}
          onChange={subjectOnChange}
          style={{width: '100%', marginBottom: '15px'}}
        />
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
        />
      </CustomDialog>
      <CustomDialog {...CustomDeleteDialogProps}>
        <h2>Bist Du dir sicher?</h2>
      </CustomDialog>
    </NewsletterWrapper>
  );
};

export default NewsletterOverview;
