import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { AUTH_STATE } from '../../enums/Enum';
import AlertDialog from '../Alert/Alert';
import {
  authenticateUser,
  registerUser,
  sendPasswordResetMail,
} from '../../services/Authentication';

import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

import './Modal.scss';

interface AuthModalProps {
  modalState: boolean;
  toggleModalState: () => void;
  emailAddress?: string;
  messageState: boolean;
}

const AuthModal = ({
  modalState,
  toggleModalState,
  emailAddress,
  messageState,
}: AuthModalProps) => {
  let navigate = useNavigate();

  if (emailAddress === undefined) {
    emailAddress = '';
  }

  const [email, setEmail] = useState(emailAddress);
  const [passwordResetEmail, setPasswordResetEmail] = useState('');
  const [passwordState, setPasswordState] = useState('password');
  const [password, setPassword] = useState('');
  const [exceptionMessage, setExceptionMessage] = useState('');
  const [exceptionHeader, setExceptionHeader] = useState('');
  const [message, setMessage] = useState('');
  const [messageHeader, setMessageHeader] = useState('');
  const [showMessage, setShowMessage] = useState(messageState);
  const [showException, setShowException] = useState(false);
  const [authState, setAuthState] = useState(AUTH_STATE.LOGIN);

  const [regEmail, setRegEmail] = useState('');
  const [regFirstName, setRegFirstName] = useState('');
  const [regLastName, setRegLastName] = useState('');
  const [regPassword, setRegPassword] = useState('');
  const [regPasswordState, setRegPasswordState] = useState('password');
  const [regPasswordDouble, setRegPasswordDouble] = useState('');
  const [regNewsletterPermission, setRegNewsletterPermission] = useState('');
  const [alertState, setAlertState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const updateAuthState = (newAuthState: AUTH_STATE) => {
    setAuthState(newAuthState);
    setShowException(false);
    setShowMessage(false);
  };

  const loginExceptionMessage =
    'Beim Abgleich deiner Anmeldedaten ist uns ein Fehler aufgefallen. ' +
    'Bitte prüfe Deine E-Mail-Adresse und Dein Passwort noch einmal ' +
    'und versuche es dann bitte nocheinmal erneut.';

  const notVerifiedAccountExceptionMessage =
    'Dein Konto ist leider noch nicht freigeschaltet. ' +
    'Bitte prüfe Deine Postfach und bestätige Deine Registrierung.';

  const passwordResetExceptionMessage =
    'Wir konnten leider kein Konto mit entsprechender E-Mail-Adresse finden. ' +
    'Bitte prüfe Deine Angaben noch einmal ' +
    'und versuche es dann bitte nocheinmal erneut.';

  const unmatchingPasswordsExceptionMessage =
    'Das eingegebene Passwort stimmt nicht mit dem wiederholten Passwort überein. ' +
    'Bitte prüfe Deine Angaben noch einmal' +
    'und versuche es dann bitte nocheinmal erneut.';

  const registrationFailedDueConflict =
    'Die E-Mail Adresse wird bereits verwendet. ' +
    'Bitte prüfe Deine Angaben noch einmal ' +
    'und versuche es dann bitte nocheinmal erneut.';

  const registrationFailedVerification =
    'Bei der Registrierung ist leider ein Fehler aufgetreten. ' +
    'Bitte prüfe Deine Angaben noch einmal ' +
    'und versuche es dann bitte nocheinmal erneut.';

  function validateLoginForm() {
    return email.length > 0 && password.length > 0;
  }

  function validatePasswordResetForm() {
    return passwordResetEmail.length > 0;
  }

  function validateRegForm() {
    return (
      regPassword.length > 0 &&
      regPasswordDouble.length > 0 &&
      regEmail.length > 0 &&
      regFirstName.length > 0 &&
      regLastName.length > 0 &&
      regNewsletterPermission != null
    );
  }

  function handleLoginSubmit(event: any) {
    event.preventDefault();
    setIsLoading(true);
    authenticateUser(email, password).then((response) => {
      if (response instanceof Error) {
        setExceptionHeader('Oh nein! Da ist etwas schief gegangen!');
        if ('401' === response.message) {
          setExceptionMessage(loginExceptionMessage);
        } else if ('406' === response.message) {
          setExceptionMessage(notVerifiedAccountExceptionMessage);
        }
        setShowException(true);
        setShowMessage(false);
      } else {
        localStorage.setItem('user', JSON.stringify(response));
        toggleModalState();
        navigate('/media');
      }
    });
    setIsLoading(false);
  }

  function handleRegistrationSubmit(event: any) {
    event.preventDefault();

    setIsLoading(true);

    if (regPassword !== regPasswordDouble) {
      setExceptionHeader('Oh nein! Da ist etwas schief gegangen!');
      setExceptionMessage(unmatchingPasswordsExceptionMessage);
      setShowException(true);
      setShowMessage(false);
      return;
    }

    registerUser(regEmail, regFirstName, regLastName, regPassword, regNewsletterPermission).then(
      (response) => {
        if (response instanceof Error) {
          if ('409' === response.message) {
            setExceptionHeader('Oh nein! Da ist etwas schief gegangen!');
            setExceptionMessage(registrationFailedDueConflict);
          } else if ('400' === response.message) {
            setExceptionHeader('Oh nein! Da ist etwas schief gegangen!');
            setExceptionMessage(registrationFailedVerification);
          }
          setShowException(true);
          setShowMessage(false);
        } else {
          updateAuthState(AUTH_STATE.LOGIN);
          setEmail(regEmail);
          setMessageHeader('Hurra!');
          setMessage('Die Registrierung war erfolgreich! Du kannst Dich nun anmelden.');
          setShowException(false);
          setShowMessage(true);
        }
      },
    );

    setIsLoading(false);
  }

  function handlePasswordResetSubmit(event: any) {
    event.preventDefault();

    setIsLoading(true);

    sendPasswordResetMail(passwordResetEmail).then((response) => {
      if (response instanceof Error) {
        if ('401' === response.message) {
          setExceptionHeader('Oh nein! Da ist etwas schief gegangen!');
          setExceptionMessage(passwordResetExceptionMessage);
          setShowException(true);
          setShowMessage(false);
        }
      } else {
        setMessageHeader('Hurra!');
        setMessage('Wir haben Dir eine E-Mail zugeschickt um deine Identität zu verifizieren.');
        setShowException(false);
        setShowMessage(true);
      }
    });

    setIsLoading(false);
  }

  function togglePasswordState(state: string, setState: (state: string) => void) {
    if (state === 'text') {
      setState('password');
    } else {
      setState('text');
    }
  }

  function getPasswordToggleIcon(state: string) {
    if (state === 'text') {
      return <BsFillEyeSlashFill></BsFillEyeSlashFill>;
    } else {
      return <BsFillEyeFill></BsFillEyeFill>;
    }
  }

  function getAccountVerified() {
    if (emailAddress) {
      setAlertState(true);
      return (
        <AlertDialog
          header="Hurra!"
          message="Deine Verifizierung war erfolgreich. Du kannst Dich jetzt anmelden."
          show={alertState}
          setShow={setAlertState}
        />
      );
    }
  }

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={modalState}
      onHide={toggleModalState}
      centered
    >
      <Modal.Header closeButton closeVariant="white" className="modal-header">
        <Modal.Title>
          {authState === AUTH_STATE.LOGIN && 'Anmeldung'}
          {authState === AUTH_STATE.REGISTRATION && 'Registrierung'}
          {authState === AUTH_STATE.PASSWORD_RESET && 'Passwort zurücksetzen'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(() => {
          if (authState === AUTH_STATE.LOGIN) {
            return (
              <Form onSubmit={handleLoginSubmit}>
                {getAccountVerified()}
                <AlertDialog
                  header={exceptionHeader}
                  message={exceptionMessage}
                  show={showException}
                  setShow={setShowException}
                ></AlertDialog>
                <Form.Group className="mb-3" controlId="loginEmail">
                  <Form.Label>E-Mail-Adresse</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="loginPassword">
                  <Form.Label>Passwort</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type={passwordState}
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                      onClick={() => togglePasswordState(passwordState, setPasswordState)}
                      style={{
                        width: '5rem',
                        backgroundColor: '#99C4D9',
                        border: '0',
                      }}
                    >
                      {getPasswordToggleIcon(passwordState)}
                    </Button>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mb-3">
                  <a
                    href="#/"
                    onClick={() => updateAuthState(AUTH_STATE.PASSWORD_RESET)}
                    className="passwort-reset-link"
                  >
                    Passwort vergessen?
                  </a>
                </Form.Group>
                <div className="buttonSet">
                  <Button
                    disabled={!validateLoginForm()}
                    type="submit"
                    style={{
                      width: '150px',
                      height: '100%',
                      color: 'white',
                      backgroundColor: '#EA86A4',
                      borderColor: '#EA86A4',
                    }}
                  >
                    {isLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}{' '}
                    {isLoading === false && 'Anmelden'}
                  </Button>
                  <Button
                    onClick={() => updateAuthState(AUTH_STATE.REGISTRATION)}
                    style={{
                      width: '225px',
                      height: '100%',
                      color: 'white',
                      backgroundColor: '#95DBDB',
                      borderColor: '#95DBDB',
                    }}
                    className="button"
                  >
                    Zur Registrierung
                  </Button>
                </div>
              </Form>
            );
          } else if (authState === AUTH_STATE.REGISTRATION) {
            return (
              <Form onSubmit={handleRegistrationSubmit}>
                <AlertDialog
                  header={exceptionHeader}
                  message={exceptionMessage}
                  show={showException}
                  setShow={setShowException}
                ></AlertDialog>
                <Form.Group className="mb-3" controlId="formBasicRegEmail">
                  <Form.Label>E-Mail-Adresse</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    autoFocus
                    value={regEmail}
                    onChange={(e) => setRegEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Kontakt</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Vorname"
                      autoFocus
                      value={regFirstName}
                      onChange={(e) => setRegFirstName(e.target.value)}
                    />
                    <Form.Control
                      placeholder="Nachname"
                      autoFocus
                      value={regLastName}
                      onChange={(e) => setRegLastName(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="loginPassword">
                  <Form.Label>Passwort</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type={regPasswordState}
                      placeholder="Password"
                      value={regPassword}
                      onChange={(e) => setRegPassword(e.target.value)}
                    />
                    <Button
                      onClick={() => togglePasswordState(regPasswordState, setRegPasswordState)}
                      style={{
                        width: '5rem',
                        backgroundColor: '#99C4D9',
                        border: '0',
                      }}
                    >
                      {getPasswordToggleIcon(regPasswordState)}
                    </Button>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="loginPassword">
                  <Form.Label>Passwort bestätigen</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type={regPasswordState}
                      placeholder="Password"
                      value={regPasswordDouble}
                      onChange={(e) => setRegPasswordDouble(e.target.value)}
                    />
                    <Button
                      onClick={() => togglePasswordState(regPasswordState, setRegPasswordState)}
                      style={{
                        width: '5rem',
                        backgroundColor: '#99C4D9',
                        border: '0',
                      }}
                    >
                      {getPasswordToggleIcon(regPasswordState)}
                    </Button>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="Newsletter abonnieren"
                    autoFocus
                    value={regNewsletterPermission}
                    onChange={(e) => setRegNewsletterPermission(e.target.value)}
                  />
                </Form.Group>

                <div className="buttonSet">
                  <Button
                    disabled={!validateRegForm()}
                    type="submit"
                    style={{
                      width: '150px',
                      height: '100%',
                      color: 'white',
                      backgroundColor: '#EA86A4',
                      borderColor: '#EA86A4',
                    }}
                  >
                    {isLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}{' '}
                    {isLoading === false && 'Registrieren'}
                  </Button>
                  <Button
                    onClick={() => updateAuthState(AUTH_STATE.LOGIN)}
                    style={{
                      width: '225px',
                      height: '100%',
                      color: 'white',
                      backgroundColor: '#95DBDB',
                      borderColor: '#95DBDB',
                    }}
                    className="button"
                  >
                    Zur Anmeldung
                  </Button>
                </div>
              </Form>
            );
          } else {
            return (
              <Form onSubmit={handlePasswordResetSubmit}>
                <AlertDialog
                  header={exceptionHeader}
                  message={exceptionMessage}
                  show={showException}
                  setShow={setShowException}
                ></AlertDialog>
                <AlertDialog
                  header={messageHeader}
                  message={message}
                  show={showMessage}
                  setShow={setShowMessage}
                ></AlertDialog>
                <Form.Group className="mb-3" controlId="passwordResetEmail">
                  <Form.Label>E-Mail-Adresse</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    autoFocus
                    value={passwordResetEmail}
                    onChange={(e) => setPasswordResetEmail(e.target.value)}
                  />
                </Form.Group>
                <div className="buttonSet">
                  <Button
                    disabled={!validatePasswordResetForm()}
                    type="submit"
                    style={{
                      width: '10rem',
                      color: 'white',
                      backgroundColor: '#EA86A4',
                      borderColor: '#EA86A4',
                    }}
                  >
                    {isLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}{' '}
                    {isLoading === false && 'Zurücksetzen'}
                  </Button>
                  <Button
                    onClick={() => updateAuthState(AUTH_STATE.LOGIN)}
                    style={{
                      width: '12rem',
                      color: 'white',
                      backgroundColor: '#95DBDB',
                      borderColor: '#95DBDB',
                    }}
                    className="button"
                  >
                    Zur Anmeldung
                  </Button>
                </div>
              </Form>
            );
          }
        })()}
      </Modal.Body>
    </Modal>
  );
};

export default AuthModal;
