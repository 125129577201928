import styled from '@emotion/styled';
import './NotFound.scss';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
`;

const ToSell = () => {
  return (
    <Container>
      <div>This domain is for sale</div>
      <div>In case you are interested to buy, please get in touch with</div>
      <div><a href="mailto:info@mindme.de">info@mindme.de</a></div>
    </Container>
  );
};
export default ToSell;
