import './Footer.scss';
import { IconButton } from '@mui/material';
import FacebookSharpIcon from '@mui/icons-material/FacebookSharp';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import styled from '@emotion/styled';

const FooterSocialLinks = [
  {
    ariaLabel: 'Facebook',
    link: 'https://facebook.com/Mindme-107251291897347/',
    color: 'white',
    icon: <FacebookSharpIcon fontSize="large" />,
  },
  {
    ariaLabel: 'Instagram',
    link: 'https://instagram.com/mind.me_psychologie',
    color: 'white',
    icon: <InstagramIcon fontSize="large" />,
  },
  {
    ariaLabel: 'LinkedIn',
    link: '#/',
    color: 'white',
    icon: <LinkedInIcon fontSize="large" />,
  },
  {
    ariaLabel: 'Pinterest',
    link: '#/',
    color: 'white',
    icon: <PinterestIcon fontSize="large" />,
  },
];

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Footer = () => {
  return (
    <footer>
      <SocialContainer>
        {FooterSocialLinks.map((link, idx) => {
          return (
            <IconButton aria-label={link.ariaLabel} key={idx} style={{ width: '50px' }}>
              <a href={link.link} key={idx} style={{ color: link.color }}>
                {link.icon}
              </a>
            </IconButton>
          );
        })}
      </SocialContainer>
      <div className="impressum">
        <a href="/imprint">Impressum</a> <span style={{ color: 'white' }}>|</span>
        <a href="/privacy">Datenschutz</a> <span style={{ color: 'white' }}>|</span>
        <a href="/contact">Kontakt</a>
      </div>
      <div className="copyright">Copyright mindme © 2022</div>
    </footer>
  );
};

export default Footer;
